import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import { useQuery, useMutation } from "@apollo/react-hooks"
import gql from "graphql-tag"
import SEO from "../components/seo"

import Layout from "../components/layout"

const Fonts = createGlobalStyle`
    @font-face {
      font-family: "Avenir";
      src: url("../fonts/medium.otf");
    }
`

const Container = styled.div`
  background-color: #121212;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
`

const Content = styled.div`
  background-color: #121212;
  display: flex;
  width: 360px;
  margin-top: 50px;
  margin-left: 20px;
  flex-direction: column;
  padding-bottom: 100px;
  min-height: 100vh;
`

const Section = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 25px;
`

const Title = styled.p`
  font-size: 35px;
  font-family: "Avenir";
  font-weight: 700;
  color: #fff;
  align-self: left;
  margin-bottom: 20px;
`

const BigTitle = styled.p`
  font-size: 60px;
  text-align: center;
  font-family: "Avenir";
  font-weight: 700;
  color: #fff;
  align-self: center;
  margin-bottom: 40px;
`

const SubTitle = styled.p`
  font-size: 30px;
  font-family: "Avenir";
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
`
const DescText = styled.p`
  font-size: 30px;
  font-family: "Avenir";
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
  line-height: 1.1;
`

const ExpireText = styled.p`
  font-size: 30px;
  font-family: "Avenir";
  font-weight: 500;
  color: #fff;
  text-align: left;
  line-height: 1.1;
`

const Line = styled.div`
  width: 100px;
  background-color: #fdde68;
  height: 13px;
  margin-bottom: 30px;
`

const HostedBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  min-width: 300px;
  min-height: 100px;
  border-radius: 25px;
  padding-left: 20px;
  padding-right: 20px;
`

const ImgBox = styled.div`
  width: 74px;
  height: 74px;
`

const ProfileBox = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const TextBox = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  align-items: center;
`

const Username = styled.div`
  font-size: 20px;
  font-family: "Avenir";
  font-weight: 500;
  color: #333;
  margin-bottom: 0px;
`

const LikeBox = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
  align-items: flex-start;
`
const Likes = styled.div`
  font-size: 25px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  margin: 0px;
  margin-top: 5px;
  margin-left: 5px;
`

const InfluenceTitle = styled.div`
  font-size: 35px;
  font-family: "Avenir";
  font-weight: 700;
  color: #fdde68;
  line-height: 1.2;
  max-width: 300px;
`

const DownloadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 240px;
  height: 58px;
  background-color: #fdde68;
  border-radius: 25px;
  font-size: 23px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
`

const BigDownloadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 300px;
  height: 75px;
  background-color: #fdde68;
  border-radius: 25px;
  font-size: 20px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  margin-top: 25px;
`

const SmallText = styled.p`
  font-size: 20px;
  font-family: "Avenir";
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 30px;
`

//Intro
const IntroSection = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
`

const YellowLongArrow = styled.div`
  align-items: center;
`

const IntroDownload = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 240px;
  height: 58px;
  background-color: #fdde68;
  border-radius: 25px;
  font-size: 23px;
  font-family: "Avenir";
  font-weight: 700;
  color: #333;
  margin-bottom: 40px;
  outline: none !important;
  box-shadow: none !important;
  border: none;
`

const GET_INVITE = gql`
  query invite($id: ID!) {
    invite(id: $id) {
      id
      joined
      by {
        displayName
        totalUpvotes
        images
      }
    }
  }
`

const JOIN_INVITE = gql`
  mutation joinedInvite($inviteId: ID!) {
    joinedInvite(inviteId: $inviteId) {
      success
    }
  }
`

export default function Invite(props) {
  const [introScreen, setIntroScreen] = useState(true)
  const [joinedInvite, setJoinedInvite] = useState(false)
  const inviteId = props.location.search.split("=").pop()
  const { loading, error, data } = useQuery(GET_INVITE, {
    variables: { id: inviteId },
  })

  const [joinInvite, { data: joinData }] = useMutation(JOIN_INVITE, {
    variables: { inviteId },
  })

  useEffect(() => {
    const hasJoined = localStorage.getItem("joined") || false
    if (hasJoined) {
      setJoinedInvite(true)
    }
  }, [])

  useEffect(() => {
    if (joinData && joinData.joinedInvite?.success) {
      setJoinedInvite(true)
      localStorage.setItem("joined", true)
      window.location.replace("http://onelink.to/pgqs55")
    }
  }, [joinData])

  const redirectInvite = () => {
    joinInvite()
  }

  if (((!data?.invite || data?.invite?.joined >= 10) && !loading) || error) {
    return (
      <Layout>
        <SEO title="Limited Queue Invite!" />
        <Container>
          <Content>
            <ExpireText>
              <p style={{ marginBottom: 0, marginTop: 0 }}>
                This influencer invite has already been claimed👀
              </p>
            </ExpireText>
            <Section>
              <InfluenceTitle>
                <p style={{ marginBottom: 0, marginTop: 0 }}>
                  You can still download -
                </p>
              </InfluenceTitle>
              <a
                href="http://onelink.to/pgqs55"
                style={{ textDecoration: "none", color: "#333" }}
              >
                <BigDownloadBox>
                  <p style={{ marginBottom: 0 }}>Join Queue</p>
                  <img
                    src={require("../images/arrow.png")}
                    style={{
                      resizeMode: "contain",
                      width: 30,
                      marginBottom: 0,
                      marginLeft: 10,
                    }}
                  />
                </BigDownloadBox>
              </a>
            </Section>
          </Content>
        </Container>
      </Layout>
    )
  }

  if (introScreen)
    return (
      <Layout>
        <SEO title="Limited Queue Invite!" />
        <Container>
          {data?.invite && (
            <Content>
              <IntroSection>
                <BigTitle>
                  <p style={{ marginBottom: 0, marginTop: 0 }}>Ready for</p>
                </BigTitle>
                <BigTitle>
                  <p style={{ marginBottom: 0, marginTop: 0 }}>summer</p>
                </BigTitle>
                <BigTitle>
                  <p style={{ marginBottom: 0, marginTop: 0 }}>parties?</p>
                </BigTitle>
              </IntroSection>
              <IntroSection>
                <YellowLongArrow>
                  <img
                    style={{
                      resizeMode: "contain",
                      width: 30,
                      height: 180,
                    }}
                    src={require("../images/yellowarrow.png")}
                  />
                </YellowLongArrow>
              </IntroSection>
              <IntroSection>
                <IntroDownload onClick={() => setIntroScreen(false)}>
                  <p style={{ marginBottom: 0, fontSize: 30 }}>Yes☀️🍻</p>
                </IntroDownload>
              </IntroSection>
            </Content>
          )}
        </Container>
      </Layout>
    )

  return (
    <Layout>
      <SEO title="Limited Queue Invite!" />
      <Container>
        <Fonts />
        {data?.invite && (
          <Content>
            <Section>
              <Title>
                <p style={{ marginBottom: 0, marginTop: 0 }}>Welcome to</p>
              </Title>
              <Title>
                <p style={{ marginBottom: 0, marginTop: 0, color: "#fdde68" }}>
                  Queue🍻
                </p>
              </Title>
            </Section>
            <Section>
              <Title>Invited By</Title>
              <Line />
              <HostedBox>
                <ProfileBox>
                  <ImgBox>
                    <img
                      style={{
                        resizeMode: "contain",
                        borderRadius: 39,
                        width: 78,
                        height: 78,
                      }}
                      src={
                        data.invite.by?.images[0]?.url ||
                        require("../images/profile.png")
                      }
                    />
                  </ImgBox>
                </ProfileBox>
                <TextBox>
                  <Username>
                    <div>
                      <p style={{ marginBottom: 0, maxWidth: 150 }}>
                        @{data.invite.by.displayName}
                      </p>
                    </div>
                  </Username>
                  <LikeBox>
                    <img
                      style={{
                        resizeMode: "contain",
                        width: 25,
                        marginBottom: 0,
                      }}
                      src={require("../images/upvote.png")}
                    />
                    <Likes>{data.invite.by.totalUpvotes}</Likes>
                  </LikeBox>
                </TextBox>
              </HostedBox>
            </Section>
            <Section>
              {joinedInvite ? (
                <ExpireText>
                  <p style={{ marginBottom: 0, marginTop: 0 }}>
                    You have already used an invite.
                  </p>
                </ExpireText>
              ) : (
                <>
                  <ExpireText>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                      {5 - data.invite.joined} downloads until this influencer
                      invite expires.
                    </p>
                  </ExpireText>

                  <button
                    style={{
                      background: "none",
                      border: "none",
                      outline: "none",
                    }}
                    onClick={() => redirectInvite()}
                  >
                    <DownloadBox>
                      <p style={{ marginBottom: 0 }}>Use Invite</p>
                      <img
                        src={require("../images/arrow.png")}
                        style={{
                          resizeMode: "contain",
                          width: 35,
                          marginBottom: 0,
                          marginLeft: 15,
                        }}
                      />
                    </DownloadBox>
                  </button>
                  <SmallText>
                    <p style={{ marginBottom: 0, marginTop: 0 }}>
                      (The invite only works on mobile)
                    </p>
                  </SmallText>
                </>
              )}
            </Section>

            <Section>
              <Title>What is Queue?</Title>
              <Line />
              <DescText>Queue is the music app for parties.</DescText>
              <DescText>
                With Queue every guest can queue their favorite songs and the
                songs everybody loves will play.
              </DescText>

              <DescText>
                Join Queue and make every party with your friends better.
              </DescText>
            </Section>
          </Content>
        )}
      </Container>
    </Layout>
  )
}
